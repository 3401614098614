<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Productos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Producto</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-producto-liquido-export"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-on:click="abrirModal('Agregar', null)"
                        data-toggle="modal"
                        data-target="#Modal_Productos"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  id="renderLineas"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Sitio
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Codigo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <th>API</th>
                      <th>% Azufre</th>
                      <th>BSW</th>
                      <th>Promedio cargue</th>
                      <th>Tolerancia perdidas</th>
                      <th>Medicion</th>
                      <th style="text-align-last: center">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in producto.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>
                        {{
                          item.sitio !== null
                            ? item.sitio.nombre
                            : "SIN ASIGNAR"
                        }}
                      </td>
                      <td>{{ item.codigo }}</td>
                      <td class="text-right" style="width: 100px">
                        {{ item.api }}
                      </td>
                      <td class="text-right" style="width: 100px">
                        {{ item.azufre }}
                      </td>
                      <td class="text-right" style="width: 100px">
                        {{ item.bsw }}
                      </td>
                      <td class="text-right" style="width: 100px">
                        {{ item.promedio_cargue }}
                      </td>
                      <td class="text-right" style="width: 100px">
                        {{ item.tolerancia_perdidas }}
                      </td>
                      <td
                        class="text-right"
                        style="width: 100px"
                        v-if="item.medicion == 0"
                      >
                        con novedad
                      </td>
                      <td
                        class="text-right"
                        style="width: 100px"
                        v-else-if="item.medicion == 1"
                      >
                        sin novedad
                      </td>
                      <td v-else></td>
                      <td class="text-right" style="width: 100px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_Productos"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.producto_liquido.show'
                              )
                            "
                            v-on:click="abrirModal('Editar', item.id)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            data-toggle="modal"
                            data-target="#Modal_campos_liquidacion"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.producto_liquido.camposLiquidacion'
                              )
                            "
                            @click="abrirCampsLiqui(item.id)"
                          >
                            <i class="fas fa-tasks"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-on:click="destroy(item.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.producto_liquido.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="producto.total">
                  <p>
                    Mostrando del <b>{{ producto.from }}</b> al
                    <b>{{ producto.to }}</b> de un total:
                    <b>{{ producto.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="producto"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Modal de agregar y editar -->
      <div class="modal fade" id="Modal_Productos">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">{{ modal.title }}</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <!-- Campo Nombre -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="descripcion">Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="nombre"
                      placeholder="Nombre Producto"
                      v-model="form.nombre"
                      :class="
                        $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <!-- Campo Código -->
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="descripcion">Código</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="codigo"
                      placeholder="Codigo Producto"
                      v-model="form.codigo"
                      :class="
                        $v.form.codigo.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <!-- Campo Api -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">API</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="api"
                      placeholder="API"
                      v-model="form.api"
                      :class="$v.form.api.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                    <small id="emailHelp" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo BSW -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">BSW</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="bsw"
                      placeholder="BSW"
                      v-model="form.bsw"
                      :class="$v.form.bsw.$invalid ? 'is-invalid' : 'is-valid'"
                    />
                    <small id="emailHelp" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo Azufre -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="estado">Azufre</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="azufre"
                      placeholder="Azufre"
                      v-model="form.azufre"
                      :class="
                        $v.form.azufre.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                    <small id="emailHelp" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo Sitio -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      class="style-chooser"
                      label="nombre"
                      :options="arraySitios"
                      placeholder="Buscar Sitios..."
                      @input="getDatosSitios"
                      v-model="nombre_Sitio"
                    >
                    </v-select>
                  </div>
                </div>
                <!-- Campo Promedio Cargue -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="promedio_cargue">Promedio Cargue</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="promedio_cargue"
                      v-model="form.promedio_cargue"
                      :class="
                        $v.form.promedio_cargue.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                </div>
                <!-- Campo Medición -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="">Medicion</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="form.medicion"
                      :class="
                        $v.form.medicion.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option value="0">con novedad</option>
                      <option value="1">sin novedad</option>
                    </select>
                  </div>
                </div>
                <!-- Campo Tolerancia a perdidas -->
                <div
                  class="col-lg-12 col-md-12 col-sm-12"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.producto_liquido.toleranciaPerdidas'
                    )
                  "
                >
                  <div class="form-group">
                    <label for="estado">Tolerancia a perdidas (%)</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="tolerancia_perdidas"
                      placeholder="Tolerancia a perdidas"
                      v-model="form.tolerancia_perdidas"
                      :class="
                        $v.form.tolerancia_perdidas.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small id="emailHelp" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.form.$invalid"
                v-if="nombre_Sitio"
                v-on:click="save()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- Modal de agregar campos liquidación guías -->
      <div class="modal fade" id="Modal_campos_liquidacion">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white">Campos Liquidación Guía</h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                id="close-modal"
                ref="closeModal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- Cuerpo del modal -->
            <div class="modal-body">
              <div class="row">
                <!-- Campo Rvp -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="rvp">Rvp</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="rvp"
                      placeholder="Rvp"
                      v-model="formLiquidacion.rvp"
                      :class="
                        $v.formLiquidacion.rvp.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small id="rvp" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo SAL PTB % -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="sal_ptb">Sal Ptb (%)</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="sal_ptb"
                      placeholder="Sal %"
                      v-model="formLiquidacion.sal_ptb"
                      :class="
                        $v.formLiquidacion.sal_ptb.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small id="sal_ptb" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo Sedimento % -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="sedimento">Sedimento (%)</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="sedimento"
                      placeholder="Sedimento %"
                      v-model="formLiquidacion.sedimento"
                      :class="
                        $v.formLiquidacion.sedimento.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small id="sedimento" class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo Incertidumbre expandida -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="porc_incertidumbre_exp"
                      >Incertidumbre expandida (%)</label
                    >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="porc_incertidumbre_exp"
                      placeholder="Incertidumbre %"
                      v-model="formLiquidacion.porc_incertidumbre_exp"
                      :class="
                        $v.formLiquidacion.porc_incertidumbre_exp.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small
                      id="porc_incertidumbre_exp"
                      class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
                <!-- Campo Incertidumbre expandida (BLS) -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="form-group">
                    <label for="bls_incertidumbre_exp"
                      >Incertidumbre expandida (BLS)</label
                    >
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="bls_incertidumbre_exp"
                      placeholder="Incertidumbre BLS"
                      v-model="formLiquidacion.bls_incertidumbre_exp"
                      :class="
                        $v.formLiquidacion.bls_incertidumbre_exp.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                    <small
                      id="bls_incertidumbre_exp"
                      class="form-text text-muted"
                      >Decimales separados por punto [.]</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-between">
              <button
                type="button"
                class="btn btn-primary"
                v-show="!$v.formLiquidacion.$invalid"
                v-on:click="saveCampsLiquidacion()"
              >
                Guardar
              </button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
    <ProductoLiquidoExport ref="ProductoLiquidoExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import ProductoLiquidoExport from "./ProductoLiquidoExport";
import Loading from "../../../../components/Loading";

export default {
  name: "Productos",
  components: {
    pagination,
    vSelect,
    ProductoLiquidoExport,
    Loading,
  },
  data() {
    return {
      cargando: false,
      producto: {},
      arraySitios: [], //array del v-select de sitios
      idSitios: "",
      nombre_Sitio: "",
      modal: {
        title: "",
        accion: "",
      },
      filtros: {
        nombre: null,
        sitio: null,
        codigo: null,
      },
      listasForms: {
        estados: [],
        tipo_docs: [],
      },
      form: {
        nombre: "",
        codigo: "",
        api: "",
        bsw: "",
        azufre: "",
        tolerancia_perdidas: "",
        medicion: "",
      },
      formLiquidacion: {
        rvp: "",
        sal_ptb: "",
        sedimento: "",
        porc_incertidumbre_exp: "",
        bls_incertidumbre_exp: "",
      },
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      codigo: {
        required,
      },
      api: {
        required,
      },
      bsw: {
        required,
      },
      azufre: {
        required,
      },
      promedio_cargue: {
        required,
      },
      tolerancia_perdidas: {
        required,
      },
      medicion: {
        required,
      },
    },

    formLiquidacion: {
      rvp: {
        required,
      },
      sal_ptb: {
        required,
      },
      sedimento: {
        required,
      },
      porc_incertidumbre_exp: {
        required,
      },
      bls_incertidumbre_exp: {
        required,
      },
    },
  },
  methods: {
    //metodo de apertura del modal
    abrirModal(accion, idlinea) {
      this.modal.accion = accion;
      this.modal.title = accion + " Producto";
      if (accion == "Editar") {
        this.show(idlinea, 1);
      } else {
        this.form = {}; // se limpian los campos de formulario
      }
    },

    abrirCampsLiqui(productoLiquidoId) {
      this.show(productoLiquidoId, 2);
    },

    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/Productos?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.producto = response.data;
          this.cargando = false;
        });
    },

    save() {
      //Se valida si el formulario y el v-select tiene datos
      if (!this.$v.form.$invalid && this.nombre_Sitio) {
        this.form.sitio_id = this.nombre_Sitio.id;
        axios
          .post("/api/hidrocarburos/Productos", this.form)
          .then((response) => {
            this.$refs.closeModal.click();
            this.form = {};
            this.nombre_Sitio = "";
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveCampsLiquidacion() {
      //Se valida si el formulario y el v-select tiene datos
      if (!this.$v.formLiquidacion.$invalid) {
        axios
          .put(
            "/api/hidrocarburos/productos/campsLiquidacion",
            this.formLiquidacion
          )
          .then((response) => {
            this.$refs.closeModal2.click();
            this.formLiquidacion = {};
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    show(idProducto, opcion) {
      this.cargando = true;
      axios
        .get("/api/hidrocarburos/Productos/show/" + idProducto)
        .then((response) => {
          if (opcion == 1) {
            this.form = response.data;
            this.nombre_Sitio = this.arraySitios.find(
              (items) => items.id === response.data.sitio_id
            );
          } else {
            this.formLiquidacion = response.data;
          }
          this.cargando = false;
        });
    },

    destroy(idProducto) {
      this.$swal({
        title: "Esta seguro de eliminar esta Producto?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/Productos/" + idProducto)
            .then(() => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title:
                  "Se elimino la Linea De Negocio de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    llenarSelectSitios() {
      let me = this;
      axios
        .get("api/admin/sitios/lista_vselect")
        .then((response) => {
          // Obtenemos los datos
          let me = this;
          let respuesta = response.data;
          me.arraySitios = respuesta.items;
          me.nombre_Sitio = me.arraySitios.find(
            (items) => items.id === me.idSitios
          );
        })
        .catch((e) => {
          // Capturamos los errores
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getDatosSitios(val1) {
      let me = this;
      me.loading = true;
      me.idSitios = val1.id;
    },
  },

  mounted() {
    this.getIndex();
    this.llenarSelectSitios();
  },
};
</script>
